const purchaseRoutes = [
  {
    path: '/purchases/automation',
    name: 'AutomationList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      title: 'Compras'
    },
    component: () => import('@/modules/purchase/automation/MonitorList')
  },
  {
    path: '/purchases',
    name: 'PurchaseList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      // permissionsRequired: ['view_purchase'],
      title: 'Recibidos'
    },
    component: () => import('@/modules/purchase/views/PurchaseList')
  },
  {
    path: '/purchases/:id',
    name: 'PurchaseRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Información del documento'
    },
    component: () => import('@/modules/purchase/views/Main')
  },
  {
    path: '/pending-receive',
    name: 'PendingReceiveList',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      // permissionsRequired: ['view_purchase'],
      title: 'Pendiente por recibir'
    },
    component: () => import('@/modules/purchase/pending-receive/views/PendingReceiveList')
  },
  {
    path: '/pending-receive/:id',
    name: 'PendingReceiveRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'PurchaseList',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Información pendiente por recibir'
    },
    component: () => import('@/modules/purchase/pending-receive/views/Main')
  }
]
export default purchaseRoutes