import store from '@/store'

const foliosRoutes = [
  {
    path: '/folios',
    name: 'FoliosListCreate',
    props: true,
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'FoliosListCreate',
      requireAuth: true,
      requireAccount: true,
      // permissionsRequired: ['view_caf', 'add_caf'],
      use: ['fc', 'ax'],
      title: 'Stock de folios',
      breadcrumbs: []
    },
    component: () => import('@/modules/folios/views/FoliosListCreate')
  },
  {
    path: '/folios/stock/:id',
    name: 'FoliosRetrieve',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'FoliosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['fc', 'ax'],
      title: 'Stock de folios',
      breadcrumbs: []
    },
    component: () => import('@/modules/folios/views/FoliosRetrieve')
  },
  {
    path: '/folios/monitor',
    name: 'FoliosMonitor',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'FoliosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      showSupport: true,
      title: 'Monitor de folios',
      breadcrumbs: []
    },
    component: () => import('@/modules/folios/views/FoliosMonitor')
  },
  {
    path: '/folios/monitor/report/braches',
    name: 'FoliosBranchReport',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'FoliosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      showSupport: true,
      title: 'Reporte Tiendas - Monitor de folios',
      breadcrumbs: []
    },
    component: () => import('@/modules/folios/views/FoliosBranchReport')
  },
  {
    path: '/folios/monitor/report/pos',
    name: 'FoliosPosReport',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'FoliosListCreate',
      requireAuth: true,
      requireAccount: true,
      use: ['ax'],
      showSupport: true,
      title: 'Reporte POS - Monitor de folios',
      breadcrumbs: []
    },
    component: () => import('@/modules/folios/views/PosReport')
  }
]
const list = () => {
  const env = process.env?.VUE_APP_MODE ?? 'ax'
  return foliosRoutes?.filter(({meta}) => meta?.use?.some((v) => {
    if (env === 'ax') {
      return store.state.auth.userAccount.is_staff ? (v === env || meta?.showSupport) : (v === env && !meta?.showSupport)
    }
    return (v === env && [undefined, true].includes(meta?.showSupport))
  }))
}
export default list()