<template>
  <v-card>
    <v-toolbar class="transparent" v-if="title" :dark="Boolean(color)" dense flat>
      <v-icon v-if="icon" left>{{icon}}</v-icon>
      <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-medium">{{title}}</v-toolbar-title>
    </v-toolbar>
    <v-divider v-if="!color" />
    <v-card-text class="py-4 px-5 grey-700--text" v-html="text"/>
    <v-divider />
    <v-card-actions class="px-5 py-4">
      <v-spacer />
      <v-dialog-actions :actions="actions" flat />
    </v-card-actions>
  </v-card>
</template>
<script>
import Confirmable from 'vuedl/src/mixins/confirmable'
import VDialogActions from './VDialogActions'
import { VCard, VCardText, VCardActions, VToolbar, VToolbarTitle, VIcon, VSpacer } from 'vuetify/lib'
export default {
  components: {
    VDialogActions,
    VCard,
    VCardText,
    VCardActions,
    VToolbar,
    VToolbarTitle,
    VIcon,
    VSpacer
  },
  layout: ['default', { width: 450 }],
  mixins: [
    Confirmable
  ],
  props: {
    color: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: [String, Function],
      required: true,
      default: ''
    }
  },
  computed: {
    getText () {
      return typeof this.text === 'function' ? this.text() : this.text
    }
  }
}
</script>