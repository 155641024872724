const customersRoutes = [
  {
    path: '/customers/:id',
    name: 'CustomerRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      title: 'Detalles y actualización de clientes',
      breadcrumbs: [
        {
          text: 'Clientes',
          to: { name: 'CustomerListCreate' },
          link: true,
          exact: true,
          disabled: false
        },
        {
          text: 'Información del cliente',
          disabled: true
        }
      ]
    },
    component: () => import('@/modules/customer/views/Main')
  },
  {
    path: '/customers',
    name: 'CustomerListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      title: 'Clientes',
      breadcrumbs: [
        {
          text: 'Clientes',
          to: { name: 'CustomerListCreate' },
          link: true,
          exact: true,
          disabled: false
        }
      ]
    },
    component: () => import('@/modules/customer/views/CustomerListCreate')
  },
  {
    path: '/contacts/:id',
    name: 'ContactRetrieveUpdate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      title: 'Detalles y actualización de la persona',
      breadcrumbs: [
        {
          text: 'Personas',
          to: { name: 'ContactListCreate' },
          link: true,
          exact: true,
          disabled: false
        },
        {
          text: 'Información del contacto',
          disabled: true
        }
      ]
    },
    component: () => import('@/modules/customer/views/ContactRetrieveUpdate')
  },
  {
    path: '/contacts',
    name: 'ContactListCreate',
    meta: {
      displayNavigationDrawer: true,
      displayOrganization: true,
      parent: 'DocumentsList',
      requireAuth: true,
      requireAccount: true,
      title: 'Personas',
      breadcrumbs: [
        {
          text: 'Personas',
          to: { name: 'ContactListCreate' },
          link: true,
          exact: true,
          disabled: false
        }
      ]
    },
    component: () => import('@/modules/customer/views/ContactListCreate')
  }
]
export default customersRoutes