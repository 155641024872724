import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'
import countries from '@/collections/countries'

const getCountry = (list = [], currentId = '') => {
  return list?.filter(({id}) => id === currentId)[0]?.id
}

const state = {
  ...stateAbstract,
  taxpayersDetail: {},
  taxpayersCount: null,
  taxpayersList: [],
  countriesCount: null,
  countriesList: countries,
  statesCount: null,
  statesList: [],
  documentTypesCount: null,
  document_typesList: [],
  currenciesCount: null,
  currenciesList: [],
  activitiesCount: null,
  activitiesList: [],
  referencesCount: null,
  referencesList: [],
  isExpandNavigationDrawer: false,
  query: {}
}

const getters = {
  currentStates: (state, _, rootState) => {
    return state.countriesList?.find(({id}) => id === getCountry(state.countriesList, rootState.auth?.account?.country))?.states ?? []
  },
  currentCountry: (state, _, rootState) => {
    return state.countriesList?.filter(({id}) => id === getCountry(state.countriesList, rootState.auth?.account?.country))[0]?.id
  }
}

const mutations = {
  EXPAND_NAVIGATION_DRAWER: (state, response) => {
    state.isExpandNavigationDrawer = response
  },
  SET_QUERY: (state, query) => {
    state.query = query
  },
  ...mutationsAbstract
}

export default {
  namespaced: true,
  getters,
  state,
  actions: actionsAbstract,
  mutations
}