import GenericViewMixin from '@/mixins/GenericViewMixin'
export default {
  props: {
    showPagination: {
      type: Boolean,
      required: false,
      default () {
        return true
      }
    },
    showCreate: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    showCriteria: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    showStatus: {
      type: Boolean,
      required: false,
      default () {
        return true
      }
    },
    hasLink: {
      type: Boolean,
      required: false,
      default () {
        return true
      }
    },
    showPermissionDenied: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    GenericViewMixin
  ],
  data () {
    return {
      page: this.$route.query?.page ? parseInt(this.$route.query?.page) : this.currentPage ?? 1,
      generalQueryList: ['ordering', 'page'],
      query: {},
      pagination: 20,
      selectedRows: [],
      dialogCreate: false,
      isClosePanel: false
    }
  },
  computed: {
    showAll () {
      return Object.keys(this.showCriteria).length
    },
    totalPages () {
      return this.count > this.pagination ? Math.ceil(this.count / this.pagination) : 1
    },
    activeGetList () {
     return Object.keys(this.$route.query).find(item => this.generalQueryList.includes(item))
    },
    emptyStateFilter () {
      return (filters) => Object.keys(this.$route.query).some((filter) => filters?.find((item) => filter.search(item.id)) ?? false)
    }
  },
  watch: {
    $route: {
      handler (val) {
        if (typeof this.getList === 'function') {
          this.getList()
        }
      },
      inmediate: true,
      deep: true
    },
    page (val) {
      if (this.$route.name !== 'AccountMembers') this.setPage()
      if (['AccountMembers', 'DocumentsBatchRetrieve'].includes(this.$route.name)) this.$emit('isActive', val)
    }
  },
  methods: {
    latestRecords (sort = '-date') {
      this.$router.replace({name: this.$route.name, params: this.$route.params, query: { ordering: `${sort},-created`} }).catch(() => {})
    },
    setQuery () {
      this.$router.replace({name: this.$route.name, query: this.query}).catch(() => {})
    },
    setPage () {
      if (this.$route.query.page === this.page) {
        return false
      }
      this.$router.replace({name: this.$route.name, query: Object.assign({}, this.$route.query, {page: this.page})})
    },
    setSort (value) {
      let query = JSON.parse(JSON.stringify(this.$route.query))
      if (['-created', 'created'].includes(value)) value = this.$route.query.ordering?.search(/-/) === -1 ? `-${value}` : value
      else {
        if (this.$store?.state?.auth?.account?.country === 'PE' && ['number', '-number', 'number,created', '-prefix,-number,-created', 'prefix,number,created'].includes(value)) {
          value = ['number,created', 'prefix,number,created'].includes(query.ordering) ? `-prefix,-${value},-created` : `prefix,${value},created`
        } else {
          if (this.$route.query.ordering === `${value},created` && this.$route.query.ordering?.search(/-/) === -1) {
            if (query.ordering === 'prefix') delete query.ordering
            value = `-${value},-created`
          } else value = `${value},created`
        }
      }
      this.$router.replace({name: this.$route.name, query: Object.assign({}, query, {ordering: value})}).catch(err => err)
    },
    setFormatParams (params = null) {
      let aux = null
      let formatParams = null
      let formatParamsTypeList = ''
      let cont = 0
      Object.entries(params).forEach(([key, value]) => {
        if (!value || typeof value === 'undefined') return
        if (typeof value === 'object') {
          cont += 1
          let result = value.map((v) => `${key}=${v}`).join('&')
          formatParamsTypeList += cont > 1 ? `&${result}` : result
        } else {
          aux = { ...aux, [key]: value }
          formatParams = Object.entries(aux).map(([k, v]) => `${k}=${v}`).join('&')
        }
      })
      return { formatParams, formatParamsTypeList }
    }
  }
}
