import { stateAbstract, actionsAbstract, mutationsAbstract } from '@/store/abstract'

const state = {
  ...stateAbstract,
  purchasesDetail: {},
  purchasesCount: null,
  purchasesList: [],
  purchase_eventsCount: 0,
  purchase_eventsList: [],
  purchase_eventsDetail: {},
  purchases_from_siiList: [],
  purchases_from_siiCount: null,
  purchases_from_siiDetail: {},
  rules_notificationList: [],
  list_automatizationList: []
}

export default {
  namespaced: true,
  state,
  actions: actionsAbstract,
  mutations: mutationsAbstract
}
